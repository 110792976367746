@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
}

.font-classifier {
  font-family: "DM Sans", sans-serif;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.filltext {
	position: relative;
}

.filltext h2:nth-child(1) {
	/* color: transparent; */
  /* color: aliceblue; */
	/* -webkit-text-stroke: 2px #000000; */
  /* -webkit-text-stroke: 1px #282828; */
	/* text-shadow: 0px 4px 4px #282828; */
}

.filltext h2:nth-child(2) {
  position: absolute;
	bottom: 0;
	animation: animate 2s ease-in-out infinite;
}


.k-train{
  position: relative;
}

.k-train *:nth-child(2){
  position: absolute;
  display: none;
  bottom: 0;
  animation: animate 2s ease-in-out infinite;
}

.k-train:hover *:nth-child(2){
  display: block;
}

.bg-train{
  position: absolute;
  z-index: 0;
  color: rgba(138, 130, 249, 0.06);
  animation: animate 2s ease-in-out infinite;
}


@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}

.topC{
  position: absolute;
  animation: topRotator ease-in-out 10s infinite;
}

.bottomC{
  /* animation: zro 2s infinite; */
  /* animation-delay: 5s; */
}


@keyframes topRotator{
  0% {
    z-index: 10;
    top: 20%;
    transform: scale(0.7);
  }

  25% {
    z-index: 10;
    top: 50%;
    transform: scale(0.4);
  }

  50% {
    z-index: 20;
    top: 80%;
    transform: scale(0.7);
  }

  75% {
    z-index: 20;
    top: 50%;
    transform: scale(1);
  }


  100% {
    z-index: 10;
    top: 20%;
    transform: scale(0.7);
  }
}

@keyframes bRotator{
  0% {
    z-index: 20;
    bottom: 5%;
    transform: scale(0.7);
  }

  25% {
    z-index: 20;
    bottom: 50%;
    transform: scale(1);
  }

  50% {
    z-index: 20;
    bottom: 80%;
    transform: scale(0.7);
  }

  75% {
    z-index: 10;
    bottom: 0%;
    transform: scale(0.4);
  }


  100% {
    z-index: 10;
    bottom: 0%;
    transform: scale(0.7);
  }
}

@keyframes zro{
  0%{
    z-index: 0;
    opacity: 100%;
  }
  25%{
    z-index: 0;
    opacity: 0%;
  }
  50%{
    z-index: 30;
    opacity: 100%;
  }
  75%{
    z-index: 30;
    opacity: 0%;
  }
  100%{
    z-index: 0;
    opacity: 100%;
  }
}